import React, { useState, useEffect } from 'react'
import styles from './DocumentationOverview.module.scss'
import { TextInput } from '@mantine/core'
import { Link } from 'gatsby'
import _ from 'lodash'
import {
  ArrowRightIcon,
  ChevronRightIcon,
  ChevronRightSolidIcon,
  SearchIcon
} from '@atlas-co/icons'

const DocumentationOverview = ({ folderList, docs, isGuides }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedDocs, setSelectedDocs] = useState([])

  // Read URL parameters when the component mounts
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const category = params.get('category')
    if (category) {
      setSelectedCategory(category)
    }
  }, [])

  // Update the URL and selected category when a category is clicked
  const handleCategoryClick = categoryName => {
    setSelectedCategory(categoryName)
    const newUrl = `?category=${encodeURIComponent(categoryName)}`
    window.history.pushState({ path: newUrl }, '', newUrl)
  }

  // Go back to the category grid
  const handleBackToCategories = () => {
    setSelectedCategory(null)
    window.history.pushState({}, '', window.location.pathname) // Clear query params
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  // Filter docs by selected category and search term
  useEffect(() => {
    const filteredDocs = docs.filter(doc => {
      const matchesCategory = selectedCategory
        ? doc.parent.relativeDirectory.includes(selectedCategory)
        : true

      const matchesSearchTerm = doc.frontmatter.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase())

      return matchesCategory && matchesSearchTerm
    })

    setSelectedDocs(filteredDocs)
  }, [selectedCategory, searchTerm, docs])

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>
            {isGuides
              ? 'Learn how to use Atlas'
              : 'Advice and answers from the Atlas Team'}
          </h1>
          <TextInput
            leftSection={<SearchIcon />}
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearch}
            radius="lg"
            size="xl"
            classNames={{ input: styles.searchInput }}
          />
        </div>

        {!searchTerm && !selectedCategory ? (
          <div className={styles.grid}>
            {folderList.map(category => (
              <div
                key={category.name}
                className={styles.gridItem}
                onClick={() => handleCategoryClick(category.name)}
              >
                <div className={styles.gridItemContent}>
                  <p className={styles.categoryTitle}>{category.name}</p>
                  <p>
                    {category.count} {isGuides ? 'guide' : 'article'}
                    {category.count > 1 ? 's' : ''}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.positions}>
            <div className={styles.positionsHeader}>
              {selectedCategory && (
                <div className={styles.breadcrumb}>
                  <span
                    style={{ color: 'var(--text-primary)' }}
                    onClick={() => handleBackToCategories()}
                  >
                    All collections
                  </span>
                  <ChevronRightSolidIcon />
                  <span>{selectedCategory}</span>
                </div>
              )}
              <h3>
                {searchTerm
                  ? `Search results for "${searchTerm}"`
                  : selectedCategory}
              </h3>
              <p>
                {selectedDocs.length} {isGuides ? 'guide' : 'article'}
                {selectedDocs.length > 1 ? 's' : ''}
              </p>
            </div>
            <div className={styles.list}>
              {selectedDocs.map(doc => (
                <Link
                  to={`/${isGuides ? 'guides' : 'docs'}/${_.kebabCase(
                    doc.frontmatter.title
                  )}/`}
                >
                  <div className={styles.position}>
                    <div className={styles.positionDetails}>
                      <h4>
                        {doc.frontmatter.title}
                        <ArrowRightIcon />{' '}
                      </h4>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DocumentationOverview
